'use client'

import { FC } from 'react'
import styles from './home.module.css'
import Image from 'next/image'
import dynamic from 'next/dynamic'

const ChatBox = dynamic(() => import('./components/ChatBox'), { ssr: false })

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const handleButtonClick = (url: string) => {
    window.open(url, '_blank', 'noopener,noreferrer')
  }

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div className={styles.header}>
          <a 
            href="https://extremevisiongaming.com/"
            target="_blank" 
            rel="noopener noreferrer" 
            aria-label="eXtreme Vision Gaming home"
          >
            <Image
              src="/xvglogo.webp"
              alt="XVG Logo"
              width={128}
              height={128}
              priority
              className={styles.logo}
            />
          </a>
          <h1 className={styles.title}>eXtreme Vision Gaming</h1>
        </div>
        <div className={styles.buttonContainer}>
          <button 
            className={styles.button}
            onClick={() => handleButtonClick('https://discord.gg/kf6dPquU2J')}
            aria-label="Join Discord"
          >
            <Image
              src="/discord-mark-white.webp"
              alt="Discord logo"
              width={24}
              height={22}
              className={styles.discordIcon}
            />
            Join Discord
          </button>
        </div>
        <div className="mt-8 w-full max-w-4xl mx-auto px-4">
          <ChatBox />
        </div>
      </div>
    </main>
  )
}

export default Home
